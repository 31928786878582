import React, { useState, useEffect } from 'react';
import { Box, DataTable, Text, Button, Form, FormField, TextInput } from 'grommet';
 
import { withFirebase } from '../Firebase';
import { set } from 'firebase/database';
 
const GenresPage = (props) => {

  const [loading, setLoading] = useState(false);
  const [genres, setGenres] = useState([]);

  useEffect(() => {
    setLoading(true);

    props.firebase.db.ref('genres').on('value', snapshot => { 
      setGenres(Object.values(snapshot.val() || {}));
      setLoading(false);
    });

    return () => {
      props.firebase.db.ref('genres').off();
    };
  }, [props.firebase.db]);

  const onAdd = (name) => {
    if (window.confirm("Are you sure you want to add the genre \"" + name + "?\"")) {
      props.firebase.db
        .ref("/genres")
        .push({name: name})
        .then(ref => {
          ref
            .child("id")
            .set(ref.key)
             .then(() => {
               setGenres([{name: name, id: ref.key}].concat(genres));
             })
             .catch(error => {
               console.log(error);
             });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  const onEdit = (genre) => {
    const newName = window.prompt("What would you like to change the name to?");
    if (newName && window.confirm("Are you sure you want to change genre \"" + genre.name + "\" to \"" + newName + "?\"")) {
      let id = genre.id;
      props.firebase.db
        .ref("/genres/" + id)
        .set({id: id, name: newName})
        .catch(error => {
          console.log(error);
        });
    }
  }

  const onRemove = (genre) => {
    if (window.confirm("Are you sure you want to delete genre \"" + genre.name+ "?\"")) {
      props.firebase.db
        .ref("/genres/" + genre.id)
        .remove()
        .catch(error => {
          console.log(error);
        });
    }
  }
 
  return (
    <div> 
      {loading && <div>Loading ...</div>}

      <Box pad="medium" gap="medium" wrap={true} fill={true} direction="row-responsive">
        <Box>
          <GenreList genres={genres} onEdit={onEdit} onRemove={onRemove} />
        </Box>
        <Box pad="small" width="small">
          <GenreAdd onAdd={onAdd} />
        </Box>
      </Box>
    </div>
  );
}

const GenreAdd = ({ onAdd }) => {
  const [value, setValue] = React.useState({});

  return (
    <Form
      value={value}
      onChange={nextValue => setValue(nextValue)}
      onReset={() => setValue({})}
      onSubmit={({ value }) => onAdd(value.name)}
    >
      <FormField name="name" htmlfor="name" label="New Genre">
        <TextInput id="name" name="name" />
      </FormField>
      <Button type="submit" primary label="Add" />
    </Form>
  );
}

const GenreList = ({ genres, onEdit, onRemove }) => (
  <DataTable
    step={30}
    primaryKey="uid"
    border={
      {
        color: "border",
        side: "both",
        size: "small"
      }
    }
    sort={
      {
        direction: 'asc',
        property: 'name'
      }
    }
    columns={[
      {
        property: 'id',
        header: <Text>ID</Text>,
        search: true,
      },
      {
        property: 'name',
        header: <Text>Name</Text>,
        search: true,
      },
      {
        header: <Text>Actions</Text>,
        search: false,
        render: datum => (
          <Box gap="xsmall">
            <Button primary label="Edit" color="neutral-3" onClick={() => onEdit(datum)} />
            <Button primary label="Delete" color="status-critical" onClick={() => onRemove(datum)} />
          </Box>
        ),
      },
    ]}
    data={genres}
  />
);
 
export default withFirebase(GenresPage);
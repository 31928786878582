import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Table, TableRow, TableCell, TableBody, Text } from 'grommet';
import { compose } from 'recompose';
import * as moment from 'moment';
 
import { withFirebase } from '../Firebase';
 
class ChatPage extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      loading: false,
      messages: []
    };
  }
 
  componentDidMount() {
    this.setState({ loading: true });

    const uid = this.props.connect.id;

    this.props.firebase.db.ref(`messages/${uid}`).on('value', snapshot => { 
      const messages = Object.values(snapshot.val() || {});

      this.setState({
        loading: false,
        messages
      });
    });
  }

  componentWillUnmount() {
    const uid = this.props.connect.id;
    this.props.firebase.db.ref(`messages/${uid}`).off();
  }

  render() {
    const { connect, target, source } = this.props;
    const { messages, loading } = this.state;
    var i = 0;
    const messageElements = messages.map((message) => {
      if (message.text) {
        const alias = message.user_id == connect.source_user_id ? source.profile.alias : target.profile.alias;
        return (
          <ChatMessage message={message} key={i++} alias={alias} />
        );
      } else if (message.source_text) {
        return (
          <ChatInfoMessage message={message} key={i++} />
        );
      } else {
        return null;
      }
    }).filter((x) => x != null);

    return (
      <div> 
        {loading && <div>Loading ...</div>}
 
        <Box direction="row-responsive" pad="medium" wrap={true} fill={true}>
          {messageElements}
        </Box>
      </div>
    );
  }
}

const ChatMessage = ({ message, alias }) => (
  <Box direction="column" gap="xlarge" fill="horizontal" overflow="scroll">
    <Box direction="row-responsive" gap="medium" pad="small">
      <Box direction="column">
        <Text weight="bold">{alias + ":"}</Text>
        <Text color="dark-4" size="small">{moment(message.timestamp).fromNow()}</Text>
      </Box>
      <Text>{message.text}</Text>
    </Box>
  </Box>
);

const ChatInfoMessage = ({ message }) => (
  <Box direction="column" gap="xlarge" fill="horizontal" overflow="scroll">
    <Box direction="row-responsive" gap="medium" pad="small">
      <Box direction="column">
        <Text weight="bold">Seen by Hirer: </Text>
      </Box>
      <Text>{message.source_text}</Text>
    </Box>
    <Box direction="row-responsive" gap="medium" pad="small">
      <Box direction="column">
        <Text weight="bold">Seen by Seller:</Text>
        <Text color="dark-4" size="small">{moment(message.timestamp).fromNow()}</Text>
      </Box>
      <Text>{message.target_text}</Text>
    </Box>
  </Box>
);
 
export default compose(
  withRouter,
  withFirebase,
)(ChatPage);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Tabs, Tab, Box, DataTable, Text } from 'grommet';
import { compose } from 'recompose';
import * as moment from 'moment';
import Modal from '../Modal';
 
import { withFirebase } from '../Firebase';

const tableSort = ({ disputes, property, direction }) => {
  const keys = property.split('.');
  const getValue = (obj) => {
    return keys.reduce((o, k) => o[k], obj);
  };
  return disputes.sort(function(a, b) {
    const left = getValue(a);
    const right = getValue(b);
    if (!left) return direction !== 'asc';
    if (!right) return direction === 'asc';
    return direction === 'asc' ? left > right : left < right
  });
};

const borderConfig = {
  header: {
    color: "border",
    side: "both",
    size: "small"
  },
  body: {
    color: "border",
    side: "both",
    size: "small"
  }
}; 

class ReviewsPage extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      loading: false,
      reviews: [],
    };
  }
 
  componentDidMount() {
    this.setState({ loading: true });
 
    this.props.firebase.db.ref('reviews').orderByChild('report').startAt('').on('value', async snapshot => {
      const reviews = Object.values(snapshot.val() || {});
      var results = [];
      for (var i = 0; i < reviews.length; i++) {
        const review = reviews[i];
        const report = review.report;
        const reviewerAlias = (await this.props.firebase.db.ref(`profiles/${review.reviewer_id}/alias`).once("value")).val();
        const revieweeAlias = (await this.props.firebase.db.ref(`profiles/${review.reviewee_id}/alias`).once("value")).val();
        const reporterAlias = (await this.props.firebase.db.ref(`profiles/${report.reported_by}/alias`).once("value")).val();

        results.push({
          id: review.id,
          text: review.text,
          reviewerId: review.reviewer_id,
          revieweeId: review.reviewee_id,
          reporterId: report.reported_by,
          report,
          reviewerAlias,
          revieweeAlias,
          reporterAlias,
        });
      }

      this.setState({
        reviews: results,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.db.ref('connect_cancels').off();
  }

  onDeleteReview(data) {
    if (window.confirm("Delete this review? (This action cannot be undone.)")) {
      var updates = {};
      updates["reviews/" + data.id] = null;
      updates["profiles/" + data.reviewer_id + "/reviews_written/" + data.id] = null;
      updates["profiles/" + data.reviewee_id + "/reviews_received/" + data.id] = null;
      this.props.firebase
          .db
          .ref()
          .update(updates)
          .catch((error) => {
            console.log(error);
          });
    }
  }
 
  render() {
    const { reviews, loading } = this.state;

    return (
      <div>
        <h1>Reviews ({reviews.length})</h1>
 
        {loading && <div>Loading ...</div>}

        <ReviewList reviews={reviews} onRemove={this.onDeleteReview} />
      </div>
    );
  }
}

const ReviewList = ({ reviews, onRemove }) => (
  <DataTable
    sortable={true}
    step={30}
    primaryKey="id"
    border={borderConfig}
    onSort={(obj) => tableSort({ reviews, ...obj }) }
    sort={
      {
        direction: 'desc',
        property: 'report.reported_at'
      }
    }
    columns={[
      {
        property: 'id',
        header: <Text>ID</Text>,
        search: true,
        sortable: false,
      },
      {
        property: 'reviewerAlias',
        header: <Text>Reviewer</Text>,
        search: true,
        sortable: true,
      },
      {
        property: 'revieweeAlias',
        header: <Text>Reviewee</Text>,
        search: true,
        sortable: true,
      },
      {
        property: 'reporterAlias',
        header: <Text>Reported By</Text>,
        search: true,
        sortable: true,
      },
      {
        header: <Text>Reported At</Text>,
        property: 'report.reported_at',
        render: datum => {
          const timestamp = datum.report.filed_at;
          return (
            <Text>{timestamp ? moment(timestamp).fromNow() : 'never'}</Text>
          )
        },
        sortable: true,
      },
      {
        property: 'text',
        header: <Text>Text</Text>,
        search: true,
        sortable: false,
      },
      {
        header: <Text>Actions</Text>,
        search: false,
        render: datum => (
          <Box gap="xsmall">
            <Button primary label="Delete Review" color="status-critical" onClick={() => onRemove(datum)} />
          </Box>
        ),
      },
    ]}
    data={reviews}
  />
);
 
export default compose(
  withRouter,
  withFirebase,
)(ReviewsPage);
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Heading, FormField, TextInput, Form, CheckBox, Box, Table, TableRow, TableCell, TableBody, Text, RadioButtonGroup, Button } from 'grommet';
import { compose } from 'recompose';
import * as moment from 'moment';
 
import { withFirebase } from '../Firebase';
 
class AppFlagsPage extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      loading: false,
      data: {},
      original: {}
    };
  }
 
  componentDidMount() {
    this.setState({ loading: true });

    const uid = this.props.match.params.uid || this.props.uid;

    this.props.firebase.db.ref(`profile_level_weights`).once('value', snapshot => { 
      const weights = snapshot.val();

      this.props.firebase.db.ref(`profile_level_thresholds`).once('value', snapshot => {
        const thresholds = snapshot.val();

        this.setState({
          original: {
            profile_level_weights: weights,
            profile_level_thresholds: thresholds
          },
          data: {
            profile_level_weights: weights,
            profile_level_thresholds: thresholds
          },
          loading: false,
        });
      });
    });
  }

  saveData(data) {
    const keys = Object.keys(data);
    var updates = {};
    const d = this.state.original;
    const dataKeys = Object.keys(d);

    for (var i = 0; i < keys.length; i++) {
      const key = keys[i];

      for (var j = 0; j < dataKeys.length; j++) {
        const dataKey = dataKeys[j];

        if (d[dataKey][key]) {
          updates[`${dataKey}/${key}`] = parseInt(data[key]);
          break;
        }
      }
    }

    this.props.firebase.db.ref("/").update(updates)
      .catch(console.log);
  }

  updateData(data) {
    const keys = Object.keys(data);
    var updates = this.state.data;
    const dataKeys = Object.keys(updates);

    for (var i = 0; i < keys.length; i++) {
      const key = keys[i];

      for (var j = 0; j < dataKeys.length; j++) {
        const dataKey = dataKeys[j];

        if (updates[dataKey][key]) {
          updates[dataKey][key] = parseInt(data[key]);
          break;
        }
      }
    }

    this.setState({ data: updates });
  }
 
  render() {
    const { data, original, loading } = this.state;

    const sections = Object.keys(original).map((section) => {
      const subdata = original[section];
      const fields = Object.keys(subdata).map((field) => {
        return (
          <FormField name={field} key={field} htmlfor={field} label={field}>
            <TextInput id={field} name={field} />
          </FormField>
        );
      })

      return (
        <Box key={section} direction="row-responsive" gap="medium" wrap={true}>
          <Heading level="3">{section}</Heading>
          {fields}
        </Box>
      )
    });

    return (
      <div> 
        {loading && <div>Loading ...</div>}
 
        <Box margin="large">
          <Form
            value={Object.values(data).reduce((a, b) => Object.assign(a, b), {})}
            onReset={() => this.setState({ data: this.state.original })}
            onChange={nextValue => this.updateData(nextValue)}
            onSubmit={({ value }) => this.saveData(value)}
          >
            {sections}
            <Box direction="row" gap="medium">
              <Button type="submit" primary label="Submit" />
              <Button type="reset" label="Reset" />
            </Box>
          </Form>
        </Box>
      </div>
    );
  }
}

 
export default compose(
  withRouter,
  withFirebase,
)(AppFlagsPage);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab, Box, DataTable, Text, Table, TableBody, TableRow, TableCell } from 'grommet';
import { compose } from 'recompose';
import * as moment from 'moment';
import Modal from '../Modal';
 
import { withFirebase } from '../Firebase';

const tableSort = ({ disputes, property, direction }) => {
  const keys = property.split('.');
  const getValue = (obj) => {
    return keys.reduce((o, k) => o[k], obj);
  };
  return disputes.sort(function(a, b) {
    const left = getValue(a);
    const right = getValue(b);
    if (!left) return direction !== 'asc';
    if (!right) return direction === 'asc';
    return direction === 'asc' ? left > right : left < right
  });
};

const borderConfig = {
  header: {
    color: "border",
    side: "both",
    size: "small"
  },
  body: {
    color: "border",
    side: "both",
    size: "small"
  }
}; 

class ErrorsPage extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      loading: false,
      isModalOpen: false,
      selectedError: null,
      errors: [],
    };
  }
 
  componentDidMount() {
    this.setState({ loading: true });
 
    this.props.firebase.db.ref('errors').on('value', async snapshot => {
      const errors = Object.values(snapshot.val() || {});
      var results = [];
      for (var i = 0; i < errors.length; i++) {
        const error = errors[i];
        const targetAlias = (await this.props.firebase.db.ref(`profiles/${error.target_user_id}/alias`).once("value")).val();

        results.push({
          error,
          targetAlias,
        });
      }

      this.setState({
        errors: results,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.db.ref('errors').off();
  }

  openChat() {
    this.setState({
      isModalOpen: true
    })
  }

  closeChat() {
    this.setState({
      isModalOpen: false
    })
  }
 
  render() {
    const { errors, loading } = this.state;

    return (
      <div>
        <h1>Errors ({errors.length})</h1>
 
        {loading && <div>Loading ...</div>}

        <ErrorList errors={errors} onClickUid={(error) => this.setState({ selectedError: error })} />
        <Modal title="Error" isOpen={this.state.isModalOpen} onClose={this.closeChat.bind(this)} error={this.state.selectedError} component={ErrorDetail} />
      </div>
    );
  }
}

const ErrorDetail = ({ error }) => (
  <Table>
    <TableBody>
      <TableRow>
        <TableCell scope="row">
          <strong>ID</strong>
        </TableCell>
        <TableCell scope="row">
          <a target="_blank" href={`https://console.firebase.google.com/u/0/project/${process.env.REACT_APP_PROJECT_ID}/database/${process.env.REACT_APP_PROJECT_ID}/data/${encodeURIComponent(`errors/${error.id}`)}`}>{error.id}</a>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell scope="row">
          <strong>Type</strong>
        </TableCell>
        <TableCell scope="row">
          {error.type}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell scope="row">
          <strong>Data</strong>
        </TableCell>
        <TableCell scope="row">
          {JSON.stringify(error.data)}
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

const ErrorList = ({ errors, onClickUid }) => (
  <DataTable
    sortable={false}
    step={30}
    primaryKey="error.id"
    border={borderConfig}
    onClickRow={({ datum }) => {
      onClickUid(datum.error);
    }}
    columns={[
      {
        property: 'error.id',
        header: <Text>ID</Text>,
        search: true,
      },
      {
        property: 'error.type',
        header: <Text>Error Type</Text>,
        search: true,
      },
      {
        property: 'targetAlias',
        header: <Text>Affected User</Text>,
        search: true,
      },
    ]}
    data={errors}
  />
);
 
export default compose(
  withRouter,
  withFirebase,
)(ErrorsPage);
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab, Box, DataTable, Text } from 'grommet';
import { compose } from 'recompose';
import * as moment from 'moment';
 
import { withFirebase } from '../Firebase';

import './index.css';

const tableSort = ({ users, property, direction }) => {
  const keys = property.split('.');
  const getValue = (obj) => {
    return keys.reduce((o, k) => o[k], obj);
  };
  return users.sort(function(a, b) {
    const left = getValue(a);
    const right = getValue(b);
    if (!left) return direction !== 'asc';
    if (!right) return direction === 'asc';
    return direction === 'asc' ? left > right : left < right
  });
};

const borderConfig = {
  header: {
    color: "border",
    side: "both",
    size: "small"
  },
  body: {
    color: "border",
    side: "both",
    size: "small"
  }
}; 

class PromotedPostsPage extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      loading: false,
      promotedPosts: [],
    };
  }
 
  async componentDidMount() {
    this.setState({ loading: true });
 
    const snapshot = await this.props.firebase.promotedPosts().once('value');
    const promotedPosts = snapshot.val();
    const promotedPostsByUserId = Object.keys(promotedPosts).reduce((acc, key) => {
      if (!acc[promotedPosts[key].user_id]) {
        acc[promotedPosts[key].user_id] = [];
      }
      acc[promotedPosts[key].user_id].push(promotedPosts[key]);
      return acc;
    }, {});

    const promotedPostsList = await Promise.all(
      Object.keys(promotedPostsByUserId).map(async key => ({
        promoted_posts: promotedPostsByUserId[key] || [],
        user: (await this.props.firebase.user(key).once('value')).val(),
        profile: (await this.props.firebase.profile(key).once('value')).val(),
        last_promoted_at: (promotedPostsByUserId[key] || []).sort((a, b) => a.promoted_at > b.promoted_at)[0].promoted_at,
        uid: key,
      }))
    )

    this.setState({
      promotedPosts: promotedPostsList,
      loading: false,
    });
  }

  componentWillUnmount() {
    this.props.firebase.promotedPosts().off();
  }
 
  render() {
    const { promotedPosts, loading } = this.state;

    return (
      <div>
        {loading && <div>Loading ...</div>}

        <Box pad="medium" overflow="scroll">
          <h1>Promoted Posts ({promotedPosts.length})</h1>
          <PromotedPostList promotedPosts={promotedPosts} onClickUid={(uid) => this.props.history.push('/users/' + uid)} />
        </Box>
      </div>
    );
  }
}

const PromotedPostList = ({ promotedPosts, onClickUid }) => (
  <DataTable
    sortable={true}
    step={30}
    primaryKey="uid"
    border={borderConfig}
    onClickRow={({ datum }) => {
      onClickUid(datum.uid);
    }}
    sort={
      {
        direction: 'desc',
        property: 'last_promoted_at'
      }
    }
    columns={[
      {
        property: 'profile.alias',
        header: <Text>Alias</Text>,
        search: true,
        sortable: true,
        size: 'small',
      },
      {
        property: 'user.last_name',
        header: <Text>Full Name</Text>,
        search: true,
        sortable: true,
        size: 'small',
        render: datum => (
          <Text>{datum.user.first_name} {datum.user.last_name}</Text>
        ),
      },
      {
        property: 'user.email',
        header: <Text>Email</Text>,
        size: 'medium',
        search: true,
        sortable: false,
      },
      {
        property: 'uid',
        header: <Text>User ID</Text>,
        search: true,
        sortable: false,
        primary: true,
        size: 'large',
      },
      {
        property: 'promoted_posts.length',
        header: <Text>Promoted Posts</Text>,
        search: false,
        sortable: true,
      },
      {
        header: <Text>Last Promoted</Text>,
        property: 'last_promoted_at',
        render: datum => {
          const timestamp = datum.last_promoted_at;
          return (
            <Text truncate="tip">{timestamp ? `${moment(timestamp).format('YYYY-MM-DD h:mm a')} (${moment(timestamp).fromNow()})` : 'never'}</Text>
          )
        },
        sortable: true,
      },
    ]}
    data={promotedPosts}
  />
);
 
export default compose(
  withRouter,
  withFirebase,
)(PromotedPostsPage);

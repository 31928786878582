import React, { useState, useEffect } from 'react';
import { Box, DataTable, Text, Button, Form, FormField, TextInput } from 'grommet';
import _ from 'lodash';
 
import { withFirebase } from '../Firebase';
 
const PromotedUsersPage = ({ firebase }) => {
  const [promotedUsers, setPromotedUsers] = useState([]);
  const [aliases, setAliases] = useState({});

  useEffect(() => {
    (async () => {
      try {
        firebase.db.ref('promoted_users').on('value', snapshot => {
          setPromotedUsers(Object.values(snapshot.val() || {}).map(value => ({ profile_id: value.profile_id || value.user_id, ...value })));
        });
      } catch (error) {
        console.log(error);
        window.alert(error.message);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const values = await Promise.all(promotedUsers
          .map(x => firebase.db
          .ref(`profiles/${x.profile_id || x.user_id}/alias`)
          .once('value')
          .then(snap => ({ [`${x.profile_id || x.user_id}`]: snap.val() }))));
        // console.log(values);
        // console.log(_.merge.apply(null, [{}].concat(values)));
        if (values.length > 0) {
          setAliases(_.merge.apply(null, [{}].concat(values)));
        }
      } catch (error) {
        console.log(error);
        window.alert(error.message);
      }
    })();
  }, [promotedUsers]);

  const savePromotedUsers = (promotedUsers) => {
    firebase.db
      .ref('promoted_users')
      .set(promotedUsers.length > 0 ? Object.assign(...promotedUsers.map(x => ({ [`${x.profile_id || x.user_id}`]: x }))) : {})
      .then(() => setPromotedUsers(promotedUsers))
      .catch(error => { console.log(error); window.alert(error.message); });
  };

  const onAdd = (profileId) => {
    savePromotedUsers([...promotedUsers, {
      profile_id: profileId,
      order: promotedUsers.length,
    }]);
  }

  const onMove = (promotedUser, up) => {
    var copy = promotedUsers;
    const index = copy.findIndex(x => (x.profile_id || x.user_id) === (promotedUser.profile_id || promotedUser.user_id));
    const newIndex = index + (up ? -1 : 1);

    if (newIndex > -1 && newIndex < copy.length) {
      const removedElement = copy.splice(index, 1)[0];
      copy.splice(newIndex, 0, removedElement);
    }

    copy.forEach((x, idx) => x.order = idx);

    savePromotedUsers(copy);
  }

  const onRemove = (promotedUser) => {
    var copy = promotedUsers;
    const index = copy.findIndex(x => (x.profile_id || x.user_id) === (promotedUser.profile_id || promotedUser.user_id));
    copy.splice(index, 1);
    copy.forEach((x, idx) => x.order = idx);

    savePromotedUsers(copy);
  }
 
  return (
    <div> 
      <Box pad="medium" gap="medium" wrap={true} fill={true} direction="row-responsive">
        <Box>
          <PromotedUsersList promotedUsers={promotedUsers.map(x => ({ alias: aliases[x.profile_id || x.user_id], ...x }))} onMove={onMove} onRemove={onRemove} />
        </Box>
        <Box pad="small" width="small">
          <PromotedUsersAdd onAdd={onAdd} />
        </Box>
      </Box>
    </div>
  );
}

const PromotedUsersAdd = ({ onAdd }) => {
  const [value, setValue] = useState({});

  return (
    <Form
      value={value}
      onChange={nextValue => setValue(nextValue)}
      onReset={() => setValue({})}
      onSubmit={({ value }) => onAdd(value.profileId.trim())}
    >
      <FormField name="profileId" htmlfor="profileId" label="Profile ID">
        <TextInput id="profileId" name="profileId" />
      </FormField>
      <Button type="submit" primary label="Add" />
    </Form>
  );
}

const PromotedUsersList = ({ promotedUsers, onMove, onRemove }) => (
  <DataTable
    step={30}
    primaryKey="profile_id"
    border={
      {
        color: "border",
        side: "both",
        size: "small"
      }
    }
    sort={
      {
        direction: 'asc',
        property: 'order'
      }
    }
    columns={[
      {
        property: 'profile_id',
        header: <Text>Profile ID</Text>,
        search: true,
      },
      {
        property: 'alias',
        header: <Text>Alias</Text>,
        search: true,
      },
      {
        header: <Text>Actions</Text>,
        search: false,
        render: datum => (
          <Box gap="xsmall">
            {datum.order !== 0 && <Button primary label="Move Up" color="neutral-3" onClick={() => onMove(datum, true)} />}
            {datum.order !== promotedUsers.length - 1 && <Button primary label="Move Down" color="neutral-3" onClick={() => onMove(datum, false)} />}
            <Button primary label="Delete" color="status-critical" onClick={() => onRemove(datum)} />
          </Box>
        ),
      },
    ]}
    data={promotedUsers}
  />
);
 
export default withFirebase(PromotedUsersPage);

import React, { Component } from 'react';
import { CheckBox, Box, Table, TableRow, TableCell, TableBody, TableHeader, Text, RadioButtonGroup, Button } from 'grommet';
import * as moment from 'moment';
 
import { withFirebase } from '../Firebase';
 
class UserPage extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      loading: false,
      user: null,
      profile: null,
      promotedPosts: [],
      genres: [],
    };
  }
 
  async componentDidMount() {
    this.setState({ loading: true });

    const uid = this.props.match.params.uid || this.props.uid;

    this.props.firebase.genres().on('value', snapshot => {
      this.setState({
        genres: snapshot.val(),
      });
    });

    this.props.firebase.user(uid).on('value', snapshot => { 
      this.setState({
        user: snapshot.val(),
        profile: this.state.profile,
        loading: !this.state.profile,
      });
    });
 
    this.props.firebase.profile(uid).on('value', snapshot => {
      this.setState({
        profile: snapshot.val(),
        user: this.state.user,
        loading: !this.state.user,
      });
    });

    this.props.firebase.promotedPosts().on('value', snapshot => {
      const promotedPosts = snapshot.val();
      const userPromotedPosts = Object.keys(promotedPosts).filter(postId => promotedPosts[postId].user_id === uid);
      this.setState({
        promotedPosts: userPromotedPosts.map(postId => promotedPosts[postId]).sort((a, b) => b.promoted_at - a.promoted_at),
      });
    });

    const snapshot = await this.props.firebase.profiles().orderByChild('referral_user_id').equalTo(uid).once('value');
    this.setState({
      profile: this.state.profile,
      user: this.state.user,
      referredUsers: snapshot.val(),
      loading: !this.state.user,
    })
  }

  componentWillUnmount() {
    const uid = this.props.match.params.uid || this.props.uid;
    this.props.firebase.user(uid).off();
    this.props.firebase.profile(uid).off();
    this.props.firebase.promotedPosts().off();
    this.props.firebase.genres().off();
  }

  onUpdatePhone() {
    console.log(this.props);
    const number = prompt('Please enter a new 10-digit phone number. Do not include the country code.');
    const uid = this.props.match.params.uid || this.props.uid;

    this.props.firebase.auth
      .currentUser
      .getIdToken()
      .then(token => {
        return fetch(
          process.env.REACT_APP_API_URL + "/support/updatePhoneNumber",
          {
            method: 'PUT',
            headers: {
              'Authorization': "Bearer " + token,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              user_id: uid,
              phone: number
            })
          }
        )
      })
      .then(response => response.json())
      .then(value => {
        alert(`Phone number updated to ${number}.`);
      })
      .catch(error => {
        alert(error);
      });
  }
 
  render() {
    const { user, profile, referredUsers, promotedPosts, genres, loading } = this.state;

    return (
      <div> 
        {loading && <div>Loading ...</div>}
 
        <Box direction="row-responsive" pad="medium" wrap={true} fill={true}>
          <Box pad="medium" width="large" fill="vertical">
            <UserDetail gridArea="left" user={user || {}} profile={profile || {}} referredUsers={referredUsers} promotedPosts={promotedPosts} genres={genres} />
          </Box>
          <Box pad="medium" width="medium" fill="vertical">
            <UserManage
              gridArea="right"
              user={user || {}}
              profile={profile || {}}
              background="light-2"
              onChangePhone={() => this.onUpdatePhone()}
              onChangeAdmin={(x) => this.props.firebase.setAdmin(user.id, x)}
              onChangeWatcher={(x) => this.props.firebase.setWatcher(user.id, x)}
              onChangeManager={(x) => this.props.firebase.setManager(user.id, x)}
              onOverrideLevel={(x) => this.props.firebase.setLevelOverride(user.id, x)}
              onDeleteAccount={() => this.props.firebase.deleteAccount(user, profile)}
              onReturnToOnboarding={() => this.props.firebase.returnToOnboarding(user.id)}
            />
          </Box>
        </Box>
      </div>
    );
  }
}

const UserManage = ({ user, profile, onChangeAdmin, onChangeWatcher, onChangeManager, onChangePhone, onOverrideLevel, onDeleteAccount, onReturnToOnboarding }) => (
  <Box gap="medium">
    <CheckBox
      checked={user.is_admin}
      label="Admin"
      onChange={(event) => onChangeAdmin(event.target.checked)}
    />
    <CheckBox
      checked={profile.is_watcher}
      label="Industry Exec"
      onChange={(event) => onChangeWatcher(event.target.checked)}
    />
    <CheckBox
      checked={profile.is_manager}
      label="Manager"
      onChange={(event) => onChangeManager(event.target.checked)}
    />
    <strong>Connect Level Override:</strong>
    <RadioButtonGroup
      name="level_override"
      options={[
        {
          value: "disabled",
          label: "Disable"
        },
        {
          value: "none",
          label: "None"
        },
        {
          value: "bronze",
          label: "Bronze"
        },
        {
          value: "silver",
          label: "Silver"
        },
        {
          value: "gold",
          label: "Gold"
        },
      ]}
      value={profile.level_override || "disabled"}
      onChange={(event) => onOverrideLevel(event.target.value)}
    />
    <Button primary color="#FFFB00" label="Return to Onboarding" onClick={onReturnToOnboarding} />
    <Button secondary label="Change Phone Number" onClick={onChangePhone} />
    <Button primary color="#FF2600" label="Delete Account" onClick={onDeleteAccount} />
  </Box>
);

const UserDetail = ({ user, profile, referredUsers, promotedPosts, genres }) => (
  <Table>
    <TableBody>
      <TableRow>
        <TableCell scope="row">
          <strong>Full Name</strong>
        </TableCell>
        <TableCell scope="row">
          {user.first_name} {user.last_name}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell scope="row">
          <strong>Alias</strong>
        </TableCell>
        <TableCell scope="row">
          {profile.alias}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell scope="row">
          <strong>ID</strong>
        </TableCell>
        <TableCell scope="row">
        <a target="_blank" href={`https://console.firebase.google.com/u/0/project/${process.env.REACT_APP_PROJECT_ID}/database/${process.env.REACT_APP_PROJECT_ID}/data/${encodeURIComponent(`users/${user.id}`)}`}>{user.id}</a>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell scope="row">
          <strong>Email</strong>
        </TableCell>
        <TableCell scope="row">
          {user.email}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell scope="row">
          <strong>Phone</strong>
        </TableCell>
        <TableCell scope="row">
          {user.phone}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell scope="row">
          <strong>Location</strong>
        </TableCell>
        <TableCell scope="row">
          {profile.location ? `${profile.location.city}, ${profile.location.state} ${profile.location.postal_code}` : 'missing'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell scope="row">
          <strong>Bio</strong>
        </TableCell>
        <TableCell scope="row">
          {profile.bio}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell scope="row">
          <strong>Submerchant ID</strong>
        </TableCell>
        <TableCell scope="row">
          {user.stripe_account_id}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell scope="row">
          <strong>Submerchant Status</strong>
        </TableCell>
        <TableCell scope="row">
          {user.stripe_account_status}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell scope="row">
          <strong>Signed Up</strong>
        </TableCell>
        <TableCell scope="row">
          {moment(profile.created_at).fromNow() + ', ' + moment(profile.created_at).format("lll")}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell scope="row">
          <strong>Last Active</strong>
        </TableCell>
        <TableCell scope="row">
          {moment(profile.last_active).fromNow() + ', ' + moment(profile.last_active).format("lll")}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell scope="row">
          <strong>Last Opened</strong>
        </TableCell>
        <TableCell scope="row">
          {moment(user.last_opened_at).fromNow() + ', ' + moment(user.last_opened_at).format("lll")}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell scope="row" style={{ top: 0 }}>
          <strong>Promoted Posts ({Object.keys(promotedPosts || {}).length})</strong>
        </TableCell>
        <TableCell scope="row" style={{ border: '1px solid black', height: 210, overflow: 'scroll' }}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope="row">
                  <strong>Post ID</strong>
                </TableCell>
                <TableCell scope="row">
                  <strong>Promoted At</strong>
                </TableCell>
                <TableCell scope="row">
                  <strong>Genre</strong>
                </TableCell>
                <TableCell scope="row">
                  <strong>Product ID</strong>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {promotedPosts.map((post) => (
                <TableRow key={post.post_id}>
                  <TableCell scope="row">
                    <a target="_blank" href={`https://console.firebase.google.com/u/0/project/${process.env.REACT_APP_PROJECT_ID}/database/${process.env.REACT_APP_PROJECT_ID}/data/${encodeURIComponent(`status_updates/${post.post_id}`)}`}>View post</a>
                  </TableCell>
                  <TableCell scope="row">
                    {moment(post.promoted_at).format("lll")}
                  </TableCell>
                  <TableCell scope="row">
                    {post.genre_ids && Object.keys(post.genre_ids).map(genreId => genres[genreId].name).join(', ')}
                    {post.genre_id && genres[post.genre_id].name}
                  </TableCell>
                  <TableCell scope="row">
                    {post.product_id}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell scope="row" style={{ top: 0 }}>
          <strong>Referred Users ({Object.keys(referredUsers || {}).length})</strong>
        </TableCell>
        <TableCell scope="row" style={{ border: '1px solid black', height: 210, overflow: 'scroll' }}>
          <Table>
            <TableBody>
              {Object.keys(referredUsers || {}).map((userId) => (
                <TableRow key={userId}>
                  <TableCell scope="row">
                    <a href={'/users/' + userId}>{referredUsers[userId].alias}</a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

 
export default withFirebase(UserPage);
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Heading, FormField, TextArea, Form, CheckBox, Box, Table, TableRow, TableCell, TableBody, Text, RadioButtonGroup, Button } from 'grommet';
import { compose } from 'recompose';
import * as moment from 'moment';
 
import { withFirebase } from '../Firebase';
 
class LegalPage extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      loading: false,
      data: {},
      original: {}
    };
  }
 
  componentDidMount() {
    this.setState({ loading: true });

    const uid = this.props.match.params.uid || this.props.uid;

    this.props.firebase.db.ref(`terms_of_use`).once('value', snapshot => { 
      const terms_of_use = snapshot.val();

      this.props.firebase.db.ref(`privacy_policy`).once('value', snapshot => {
        const privacy_policy = snapshot.val();

        this.setState({
          original: {
            terms_of_use,
            privacy_policy
          },
          data: {
            terms_of_use,
            privacy_policy
          },
          loading: false,
        });
      });
    });
  }

  saveData(data) {
    this.props.firebase.db.ref("/").update(data)
      .catch(console.log);
  }

  updateData(data) {
    console.log(data);

    this.setState({ data: data });
  }
 
  render() {
    const { data, original, loading } = this.state;

    const sections = Object.keys(original).map((field) => {
      return (
        <FormField name={field} key={field} htmlfor={field} label={field}>
          <TextArea id={field} name={field} size="xlarge" />
        </FormField>
      )
    });

    return (
      <div> 
        {loading && <div>Loading ...</div>}
 
        <Box margin="large">
          <Form
            value={data}
            onReset={() => this.setState({ data: this.state.original })}
            onChange={nextValue => this.updateData(nextValue)}
            onSubmit={({ value }) => this.saveData(value)}
          >
            {sections}
            <Box direction="row" gap="medium">
              <Button type="submit" primary label="Submit" />
              <Button type="reset" label="Reset" />
            </Box>
          </Form>
        </Box>
      </div>
    );
  }
}

 
export default compose(
  withRouter,
  withFirebase,
)(LegalPage);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { CheckBox, Box, Table, TableRow, TableCell, TableBody, TableHeader, Text, RadioButtonGroup, Button } from 'grommet';
import { compose } from 'recompose';
import * as moment from 'moment';
 
import { withFirebase } from '../Firebase';
import Chat from '../Chat';
import Modal from '../Modal';
 
class ConnectPage extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      loading: false,
      isChatOpen: false,
      source: null,
      target: null,
      connect: null,
      genre: null,
      info: null
    };
  }
 
  componentDidMount() {
    this.setState({ loading: true });

    const uid = this.props.match.params.uid || this.props.uid;

    this.props.firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken()
          .then(token => {
            return fetch(
              process.env.REACT_APP_API_URL + "/support/connect/" + uid,
              {
                method: 'GET',
                headers: {
                  'Authorization': "Bearer " + token,
                  'Content-Type': 'application/json'
                }
              }
            )
          })
          .then(response => response.json())
          .then(info => {
            this.setState({ info })
          })
          .catch(error => {
            console.log(error);
          });;
      }
    });

    this.props.firebase.db.ref(`connects/${uid}`).on('value', connectSnap => { 
      const connect = connectSnap.val();

      this.props.firebase.db.ref(`genres/${connect.service.genre_id}`).once('value', genreSnap => {
        this.props.firebase.user(connect.target_user_id).once('value', targetUserSnap => { 
          this.props.firebase.profile(connect.target_user_id).once('value', targetProfileSnap => {
            this.props.firebase.user(connect.source_user_id).once('value', sourceUserSnap => { 
              this.props.firebase.profile(connect.source_user_id).once('value', sourceProfileSnap => {
                this.setState({
                  connect: connect,
                  genre: genreSnap.val(),
                  source: {
                    profile: sourceProfileSnap.val(),
                    user: sourceUserSnap.val()
                  },
                  target: {
                    profile: targetProfileSnap.val(),
                    user: targetUserSnap.val()
                  },
                  loading: false,
                });
              });
            });
          });
        });
      });
    });
  }

  componentWillUnmount() {
    const uid = this.props.match.params.uid || this.props.uid;
    this.props.firebase.db.ref(`connects/${uid}`).off();
  }

  openChat() {
    this.setState({
      isChatOpen: true
    })
  }

  closeChat() {
    this.setState({
      isChatOpen: false
    })
  }

  refundDeposit() {
    if (window.confirm("This will refund the deposit to the hiring user. Do you want to proceed?")) {
      this.refundTransactions(true, "refunded_deposit");
    }
  }

  refundPrimary() {
    if (window.confirm("This will refund the primary transaction to the hiring user. Do you want to proceed?")) {
      this.refundTransactions(false, "refunded_primary_transaction");
    }
  }

  disburseDeposit() {
    if (window.confirm("This will disburse the deposit to the service provider. Do you want to proceed?")) {
      this.disburseTransactions(true, "disbursed_deposit");
    }
  }

  disbursePrimary() {
    if (window.confirm("This will disburse the primary transaction to the service provider. Do you want to proceed?")) {
      this.disburseTransactions(false, "disbursed_primary");
    }
  }

  forceFinalize() {
    if (window.confirm("This will finalize the connect as if the seller did it. Do you want to continue?")) {
      this.doForceFinalize();
    }
  }

  resolveDispute() {
    if (window.confirm("This will remove the connect from both users' incoming/outgoing list. Do you want to continue?")) {
      this.doForceFinalize();
    }
  }

  doForceFinalize() {
    let connectID = this.state['connect']['id'];
    this.props.firebase.auth
      .currentUser
      .getIdToken()
      .then(token => {
        return fetch(
          process.env.REACT_APP_API_URL + "/support/finalize",
          {
            method: 'POST',
            headers: {
              'Authorization': "Bearer " + token,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ connect_id: connectID })
          }
        )
      })
      .then(response => response.text())
      .then(value => {
        alert(value);
      })
      .catch(error => {
        alert(error);
      });
  }

  deleteDispute() {
    if (window.confirm("Are you sure? This action cannot be undone.")) {
      let that = this;
      this.props.firebase.db
        .ref("/connect_cancels/" + this.data['connect']['id'])
        .set(null)
        .catch(error => {
          alert(error.message);
        });
    }
  }

  refundTransactions(isDeposit, eventName) {
    this.props.firebase.auth
      .currentUser
      .getIdToken()
      .then(token => {
        return fetch(
          process.env.REACT_APP_API_URL + "/support/refund",
          {
            method: 'POST',
            headers: {
              'Authorization': "Bearer " + token,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              connect_id: this.state.connect.id,
              event_name: eventName,
              refund_deposit: isDeposit,
            })
          }
        )
      })
      .then(response => response.text())
      .then(value => {
        alert(value);
      })
      .catch(error => {
        alert(error);
      });
  }

  disburseTransactions(isDeposit, eventName) {
    this.props.firebase.auth
      .currentUser
      .getIdToken()
      .then(token => {
        return fetch(
          process.env.REACT_APP_API_URL + "/support/payout",
          {
            method: 'POST',
            headers: {
              'Authorization': "Bearer " + token,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              connect_id: this.state.connect.id,
              event_name: eventName,
              payout_deposit: isDeposit,
            })
          }
        )
      })
      .then(response => response.text())
      .then(value => {
        alert(value);
      })
      .catch(error => {
        alert(error);
      });
  }

 
  render() {
    const { source, target, connect, genre, info, loading } = this.state;

    return (
      <div> 
        {loading && <div>Loading ...</div>}
 
        <Box direction="row-responsive" pad="medium" wrap={true} fill={true}>
          <Box pad="medium" width="large" fill="vertical">
            <ConnectDetail gridArea="left" info={info} genre={genre} connect={connect} source={source || {}} target={target || {}} />
          </Box>
          <Box pad="medium" width="medium" fill="vertical">
            <ConnectManage
              gridArea="right"
              connect={connect || {}}
              source={source || {}}
              target={target || {}}
              background="light-2"
              onViewChat={() => this.openChat()}
              onRefundDeposit={() => this.refundDeposit()}
              onRefundPrimary={() => this.refundPrimary()}
              onDisburseDeposit={() => this.disburseDeposit()}
              onDisbursePrimary={() => this.disbursePrimary()}
              onResolveDispute={() => this.resolveDispute()}
              onDeleteDispute={() => this.deleteDispute()}
              onForceFinalize={() => this.forceFinalize()}
            />
          </Box>
          {/*<TransactionsDetail
            onChargeBack={(a, b) => this.onChargeBack(a, b)}
            onRefundDeposit={() => this.refundDeposit()}
            onRefundPrimary={() => this.refundPrimary()}
            info={info} genre={genre} connect={connect} source={source || {}} target={target || {}} />*/}
        </Box>
        <Modal title="Chat" isOpen={this.state.isChatOpen} onClose={this.closeChat.bind(this)} source={source} target={target} connect={connect} component={Chat} />
      </div>
    );
  }
}

const ConnectManage = ({ connect, target, source, onViewChat, onRefundDeposit, onForceFinalize, onRefundPrimary, onDisburseDeposit, onDisbursePrimary, onResolveDispute, onDeleteDispute }) => (
  <Box gap="medium">
    <Button primary label="View Chat" onClick={onViewChat} />
    {!connect.stripe_deposit_refund_id && connect.stripe_deposit_payment_intent_id && <Button primary label="Refund Deposit" onClick={onRefundDeposit} />}
    {!connect.stripe_refund_id && connect.stripe_payment_intent_id && <Button primary label="Refund Primary" onClick={onRefundPrimary} />}
    {!connect.stripe_deposit_payout_id && connect.stripe_deposit_payment_intent_id && !connect.stripe_deposit_refund_id && <Button primary label="Payout Deposit" onClick={onDisburseDeposit} />}
    {!connect.stripe_deposit_payout_id && connect.stripe_payment_intent_id && !connect.stripe_refund_id && <Button primary label="Payout Primary" onClick={onDisbursePrimary} />}
    <Button primary label="Force Finalize" onClick={onForceFinalize} />
    <Button primary label="Resolve Dispute" onClick={onResolveDispute} />
    <Button primary label="Delete Dispute" onClick={onDeleteDispute} />
  </Box>
);

const ConnectDetail = ({ info, connect, genre, source, target, dispute }) => {
  if (!connect) {
    return (
      <div></div>
    );
  }

  const stateLog = connect.state_changelog;

  if (Object.keys(stateLog).indexOf('dispute_resolved') > -1 && Object.keys(stateLog).indexOf('finalized') > -1) {
    delete stateLog['finalized'];
  }

  var states = [];
  for (let state in stateLog) {
    states.push({
      state: state,
      date: moment(stateLog[state])
    });
  }
  const stateLogItems = states
    .sort((a, b) => {
      return a.date > b.date ? 1 : -1;
    })
    .map(change => {
      return (
        <li key={change.state}>{change.state + " " + change.date.format("MMM D YYYY h:mm a") + " (" + change.date.fromNow() + ")"}</li>
      );
    });
  const cancelUser = connect['canceling_user_id'] == connect['source_user_id'] ? source : target;
  const finalizeUser = connect['finalizing_user_id'] == connect['source_user_id'] ? source : target;

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell scope="row">
            <strong>ID</strong>
          </TableCell>
          <TableCell scope="row">
            <a target="_blank" href={`https://console.firebase.google.com/u/0/project/${process.env.REACT_APP_PROJECT_ID}/database/${process.env.REACT_APP_PROJECT_ID}/data/${encodeURIComponent(`connects/${connect.id}`)}`}>{connect.id}</a>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">
            <strong>State Log</strong>
          </TableCell>
          <TableCell scope="row">
            <ul>
              {stateLogItems}
            </ul>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">
            <strong>Service Provider</strong>
          </TableCell>
          <TableCell scope="row">
            <a href={`/users/${target.user['id']}`}>{target.user['first_name'] + " " + target.user['last_name'] + " (" + target.user['email'] + ")"}</a>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">
            <strong>Hiring User</strong>
          </TableCell>
          <TableCell scope="row">
            <a href={`/users/${source.user['id']}`}>{source.user['first_name'] + " " + source.user['last_name'] + " (" + source.user['email'] + ")"}</a>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">
            <strong>Canceled By</strong>
          </TableCell>
          <TableCell scope="row">
            {connect['canceling_user_id'] ? (cancelUser.user['first_name'] + " " + cancelUser.user['last_name'] + " (" + cancelUser.user['email'] + ")") : "N/A"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">
            <strong>Finalized By</strong>
          </TableCell>
          <TableCell scope="row">
            {connect['finalizing_user_id'] ? (finalizeUser.user['first_name'] + " " + finalizeUser.user['last_name'] + " (" + finalizeUser.user['email'] + ")") : "N/A"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">
            <strong>Service</strong>
          </TableCell>
          <TableCell scope="row">
            {connect['service']['service'] + ", $" + (connect['service']['kind'] == 'hourly' ? connect['service']['rate'] + "/hr" : connect['service']['rate'])}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">
            <strong>Deposit</strong>
          </TableCell>
          <TableCell scope="row">
            {connect['deposit'] == null ? "None" : "$" + connect['deposit']}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">
            <strong>Payment Type</strong>
          </TableCell>
          <TableCell scope="row">
            {connect['payment_type'] || "pending"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">
            <strong>Genre</strong>
          </TableCell>
          <TableCell scope="row">
            {genre.name}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">
            <strong>Deposit Payment</strong>
          </TableCell>
          <TableCell scope="row">
            <a target="_blank" href={connect['stripe_deposit_payment_intent_id'] == null ? null : process.env.REACT_APP_STRIPE_URL + "/payments/" + connect['stripe_deposit_payment_intent_id']}>{connect['stripe_deposit_payment_intent_id'] == null ? "None" : "View on Stripe"}</a>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">
            <strong>Primary Payment</strong>
          </TableCell>
          <TableCell scope="row">
            <a target="_blank" href={connect['stripe_payment_intent_id'] == null ? null : process.env.REACT_APP_STRIPE_URL + "/payments/" + connect['stripe_payment_intent_id']}>{connect['stripe_payment_intent_id'] == null ? "None" : "View on Stripe"}</a>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">
            <strong>Deposit Payout</strong>
          </TableCell>
          <TableCell scope="row">
            <a target="_blank" href={connect['stripe_deposit_payout_id'] == null ? null : process.env.REACT_APP_STRIPE_URL + "/payouts/" + connect['stripe_deposit_payout_id']}>{connect['stripe_deposit_payout_id'] == null ? "None" : "View on Stripe"}</a>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">
            <strong>Primary Payout</strong>
          </TableCell>
          <TableCell scope="row">
            <a target="_blank" href={connect['stripe_payout_id'] == null ? null : process.env.REACT_APP_STRIPE_URL + "/payouts/" + connect['stripe_payout_id']}>{connect['stripe_payout_id'] == null ? "None" : "View on Stripe"}</a>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">
            <strong>Deposit Refund</strong>
          </TableCell>
          <TableCell scope="row">
            <a target="_blank" href={connect['stripe_deposit_refund_id'] == null ? null : process.env.REACT_APP_STRIPE_URL + "/refunds/" + connect['stripe_deposit_refund_id']}>{connect['stripe_deposit_refund_id'] == null ? "None" : "View on Stripe"}</a>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">
            <strong>Primary Refund</strong>
          </TableCell>
          <TableCell scope="row">
            <a target="_blank" href={connect['stripe_refund_id'] == null ? null : process.env.REACT_APP_STRIPE_URL + "/refunds/" + connect['stripe_refund_id']}>{connect['stripe_refund_id'] == null ? "None" : "View on Stripe"}</a>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

// const TransactionsDetail = ({ info, connect, genre, source, target, dispute, onRefundPrimary, onRefundDeposit }) => {
//   if (!connect || !info) {
//     return (
//       <div></div>
//     );
//   }

//   return (
//     <Table>
//       <TableHeader>
//         <TableRow>
//           <TableCell scope="col" border="bottom">
//             Date
//           </TableCell>
//           <TableCell scope="col" border="bottom">
//             Type
//           </TableCell>
//           <TableCell scope="col" border="bottom">
//             Amount
//           </TableCell>
//           <TableCell scope="col" border="bottom">
//             Status
//           </TableCell>
//           <TableCell scope="col" border="bottom">
//             Action
//           </TableCell>
//           <TableCell scope="col" border="bottom">
//             Transaction ID
//           </TableCell>
//           <TableCell scope="col" border="bottom">
//             Refunded Transaction ID
//           </TableCell>
//           <TableCell scope="col" border="bottom">
//             Credit Card
//           </TableCell>
//           <TableCell scope="col" border="bottom">
//             Actions
//           </TableCell>
//         </TableRow>
//       </TableHeader>
//       <TableBody>
//         {info.transactions.map(({ transaction, title, isRefund, isPrimary, isDeposit }) => (
//           <TableRow key={transaction.id}>
//             <TableCell scope="row">
//               {`${moment(transaction.createdAt).format("MMM D YYYY h:mm a")} (${moment(transaction.createdAt).fromNow()})`}
//             </TableCell>
//             <TableCell scope="row">
//               {transaction.type}
//             </TableCell>
//             <TableCell scope="row">
//               {`${transaction.amount} ${transaction.currencyIsoCode}`}
//             </TableCell>
//             <TableCell scope="row">
//               {transaction.status}
//             </TableCell>
//             <TableCell scope="row">
//               {title}
//             </TableCell>
//             <TableCell scope="row">
//               <a target="_blank" href={process.env.REACT_APP_STRIPE_URL + "/payments/" + transaction.id}>{transaction.id}</a>
//             </TableCell>
//             <TableCell scope="row">
//               <a target="_blank" href={process.env.REACT_APP_STRIPE_URL + "/payments/" + transaction.refundedTransactionId}>{transaction.refundedTransactionId}</a>
//             </TableCell>
//             <TableCell scope="row">
//               <a target="_blank" href={process.env.REACT_APP_STRIPE_URL + "/payment_methods/any/" + transaction.creditCard.token}>{transaction.creditCard.cardType} {transaction.creditCard.last4}</a>
//             </TableCell>
//             <TableCell scope="row">
//               {isRefund && transaction.creditCard && <Button primary label="Re-Charge" onClick={() => { onChargeBack(transaction, connect.id) }} />}
//               {isPrimary && <Button primary label="Refund" onClick={onRefundPrimary} />}
//               {isDeposit && <Button primary label="Refund" onClick={onRefundDeposit} />}
//             </TableCell>
//           </TableRow>
//         ))}
//       </TableBody>
//     </Table>
//   );
// };

 
export default compose(
  withRouter,
  withFirebase,
)(ConnectPage);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab, Box, DataTable, Text } from 'grommet';
import { compose } from 'recompose';
import * as moment from 'moment';
 
import { withFirebase } from '../Firebase';

const tableSort = ({ users, property, direction }) => {
  const keys = property.split('.');
  const getValue = (obj) => {
    return keys.reduce((o, k) => o[k], obj);
  };
  return users.sort(function(a, b) {
    const left = getValue(a);
    const right = getValue(b);
    if (!left) return direction !== 'asc';
    if (!right) return direction === 'asc';
    return direction === 'asc' ? left > right : left < right
  });
};

const borderConfig = {
  header: {
    color: "border",
    side: "both",
    size: "small"
  },
  body: {
    color: "border",
    side: "both",
    size: "small"
  }
}; 

class ReferralsPage extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      loading: false,
      users: [],
    };
  }
 
  async componentDidMount() {
    this.setState({ loading: true });
 
    const referringUsersSnapshot = await this.props.firebase.profiles().orderByChild('referral_user_id').startAt('').once('value');
    const referralUserIds = [...new Set(Object.values(referringUsersSnapshot.val()).map((x) => x.referral_user_id))];
    const profilesObject = await Promise.all(referralUserIds.map((uid) => this.props.firebase.profile(uid).once('value')));
    const usersList = profilesObject.reduce((acc, next) => {
      const accumulated = acc;
      const val = next.val();
      if (val) {
        accumulated[val.user_id] = {
          profile: val,
          uid: val.user_id,
        };
      }
      return accumulated;
    }, {});

    const promises = [];

    for (let userId in usersList) {
      promises.push(Promise.all([
        this.props.firebase.user(userId).once('value'),
        this.props.firebase.profiles().orderByChild('referral_user_id').equalTo(userId).once('value').then((x) => {
          if (!x || !x.val()) return 0;
          return Object.keys(x.val()).length;
        })
      ]));
    }

    Promise.all(promises)
      .then(snapshots => {
        for (let [snap, referrals] of snapshots) {
          const user = snap.val();
          // console.log(user);
          if (referrals > 0) {
            usersList[user.id].user = user;
            usersList[user.id].referrals = referrals;
          } else {
            delete usersList[user.id];
          }
        }

        this.setState({
          users: usersList,
          loading: false,
        });
      });
  }
 
  render() {
    const { users, loading } = this.state;

    return (
      <div>
        {loading && <div>Loading ...</div>}

        <Box pad="medium">
          <h1>Industry Elites ({Object.values(users).length})</h1>
          <ReferralsList users={Object.values(users)} onClickUid={(uid) => this.props.history.push('/users/' + uid)} />
        </Box>
      </div>
    );
  }
}

const ReferralsList = ({ users, onClickUid }) => (
  <DataTable
    sortable={true}
    step={30}
    primaryKey="uid"
    border={borderConfig}
    onClickRow={({ datum }) => {
      onClickUid(datum.uid);
    }}
    onSort={(obj) => tableSort({ users, ...obj }) }
    sort={
      {
        direction: 'desc',
        property: 'referrals'
      }
    }
    columns={[
      {
        property: 'profile.alias',
        header: <Text>Alias</Text>,
        search: true,
        sortable: true,
      },
      {
        property: 'user.last_name',
        header: <Text>Full Name</Text>,
        search: true,
        sortable: true,
        render: datum => (
          <Text>{datum.user.first_name} {datum.user.last_name}</Text>
        ),
      },
      {
        property: 'referrals',
        header: <Text>Referrals</Text>,
        search: false,
        sortable: true,
      },
      {
        property: 'uid',
        header: <Text>ID</Text>,
        search: true,
        sortable: false,
      },
      {
        header: <Text>Last Opened</Text>,
        property: 'user.last_opened_at',
        render: datum => {
          const timestamp = datum.user.last_opened_at;
          return (
            <Text>{timestamp ? moment(timestamp).fromNow() : 'never'}</Text>
          )
        },
        sortable: true,
      },
      {
        header: <Text>Last Active</Text>,
        property: 'profile.last_active',
        render: datum => {
          const timestamp = datum.profile.last_active;
          return (
            <Text>{timestamp ? moment(timestamp).fromNow() : 'never'}</Text>
          )
        },
        sortable: true,
      },
      {
        header: <Text>Signed Up</Text>,
        property: 'profile.created_at',
        render: datum => {
          const timestamp = datum.profile.created_at;
          return (
            <Text>{timestamp ? moment(timestamp).fromNow() : 'never'}</Text>
          )
        },
        sortable: true,
      },
    ]}
    data={users}
  />
);

const ReportedUserList = ({ reports, onClickUid }) => (
  <DataTable
    sortable={true}
    step={30}
    primaryKey="uid"
    border={borderConfig}
    onClickRow={({ datum }) => {
      onClickUid(datum.uid);
    }}
    onSort={(obj) => tableSort({ reports, ...obj }) }
    sort={
      {
        direction: 'desc',
        property: 'report.reported_at'
      }
    }
    columns={[
      {
        property: 'profile.alias',
        header: <Text>Alias</Text>,
        search: true,
        sortable: true,
      },
      {
        property: 'user.last_name',
        header: <Text>Full Name</Text>,
        search: true,
        sortable: true,
        render: datum => (
          <Text>{datum.user.first_name} {datum.user.last_name}</Text>
        ),
      },
      {
        property: 'uid',
        header: <Text>ID</Text>,
        search: true,
        sortable: false,
      },
      {
        property: 'reporter.alias',
        header: <Text>Reported By</Text>,
        search: true,
        sortable: false,
        render: datum => (
          <Text>{datum.reporter.first_name} {datum.reporter.last_name} ({datum.reporter.alias})</Text>
        ),
      },
      {
        property: 'report.report_reason',
        header: <Text>Reason</Text>,
        search: true,
        sortable: false,
      },
      {
        header: <Text>Reported At</Text>,
        property: 'report.reported_at',
        render: datum => {
          const timestamp = datum.report.reported_at;
          return (
            <Text>{timestamp ? moment(timestamp).fromNow() : 'never'}</Text>
          )
        },
        sortable: true,
      },
      {
        header: <Text>Industry Exec</Text>,
        property: 'profile.is_watcher',
        render: datum => (
          <Text>{datum.user.is_watcher === true ? "✅" : "❌"}</Text>
        ),
        sortable: true,
      },
      {
        header: <Text>Last Opened</Text>,
        property: 'user.last_opened_at',
        render: datum => {
          const timestamp = datum.user.last_opened_at;
          return (
            <Text>{timestamp ? moment(timestamp).fromNow() : 'never'}</Text>
          )
        },
        sortable: true,
      },
    ]}
    data={reports}
  />
);
 
export default compose(
  withRouter,
  withFirebase,
)(ReferralsPage);
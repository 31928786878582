import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab, Box, DataTable, Text } from 'grommet';
import { compose } from 'recompose';
import * as moment from 'moment';
 
import { withFirebase } from '../Firebase';

const tableSort = ({ connects, property, direction }) => {
  const keys = property.split('.');
  const getValue = (obj) => {
    return keys.reduce((o, k) => o[k], obj);
  };
  return connects.sort(function(a, b) {
    const left = getValue(a);
    const right = getValue(b);
    if (!left) return direction !== 'asc';
    if (!right) return direction === 'asc';
    return direction === 'asc' ? left > right : left < right
  });
};

const borderConfig = {
  header: {
    color: "border",
    side: "both",
    size: "small"
  },
  body: {
    color: "border",
    side: "both",
    size: "small"
  }
}; 

class ConnectsPage extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      loading: false,
      connects: [],
      aliases: {},
    };
  }
 
  componentDidMount() {
    this.setState({ loading: true });

    const prepare = (connects, aliases) => {
      if (!aliases) return connects;

      // console.log(aliases);

      return Object.keys(connects).map(connectId => {
        const connect = connects[connectId];

        // console.log(Object.keys(aliases).filter((alias) => aliases[alias] === connect.target_user_id));

        return {
          target_alias: Object.keys(aliases).filter((alias) => aliases[alias] === connect.target_user_id)[0],
          source_alias: Object.keys(aliases).filter((alias) => aliases[alias] === connect.source_user_id)[0],
          ...connect,
        };
      });
    };
 
    this.props.firebase.db.ref('connects').on('value', snapshot => {
      this.props.firebase.db.ref('index/alias').once('value', aliasSnap => {
        this.setState({
          connects: prepare(snapshot.val() || {}, aliasSnap.val() || {}),
          aliases: aliasSnap.val() || {},
          loading: false,
        });
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.db.ref('connects').off();
  }
 
  render() {
    const { connects, loading } = this.state;

    return (
      <div>
        <h1>Connects ({connects.length})</h1>
 
        {loading && <div>Loading ...</div>}

        <ConnectList connects={connects} onClickUid={(uid) => this.props.history.push('/connects/' + uid)} />
      </div>
    );
  }
}

const ConnectList = ({ connects, onClickUid }) => (
  <DataTable
    sortable={true}
    step={30}
    primaryKey="id"
    border={borderConfig}
    onClickRow={({ datum }) => {
      onClickUid(datum.id);
    }}
    onSort={(obj) => tableSort({ connects, ...obj }) }
    sort={
      {
        direction: 'desc',
        property: 'created_at'
      }
    }
    columns={[
      {
        property: 'id',
        header: <Text>ID</Text>,
        search: true,
        sortable: false,
      },
      {
        property: 'state',
        header: <Text>State</Text>,
        search: true,
        sortable: true,
      },
      {
        property: 'target_alias',
        header: <Text>Seller</Text>,
        search: true,
        sortable: true,
      },
      {
        property: 'source_alias',
        header: <Text>Buyer</Text>,
        search: true,
        sortable: true,
      },
      {
        property: 'service.rate',
        header: <Text>Rate</Text>,
        search: true,
        sortable: true,
      },
      {
        header: <Text>Created At</Text>,
        property: 'created_at',
        render: datum => {
          const timestamp = datum.created_at;
          return (
            <Text>{timestamp ? moment(timestamp).fromNow() : 'never'}</Text>
          )
        },
        sortable: true,
      },
    ]}
    data={connects}
  />
);
 
export default compose(
  withRouter,
  withFirebase,
)(ConnectsPage);
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab, Box, DataTable, Text } from 'grommet';
import { compose } from 'recompose';
import * as moment from 'moment';
import Modal from '../Modal';
 
import { withFirebase } from '../Firebase';

const tableSort = ({ disputes, property, direction }) => {
  const keys = property.split('.');
  const getValue = (obj) => {
    return keys.reduce((o, k) => o[k], obj);
  };
  return disputes.sort(function(a, b) {
    const left = getValue(a);
    const right = getValue(b);
    if (!left) return direction !== 'asc';
    if (!right) return direction === 'asc';
    return direction === 'asc' ? left > right : left < right
  });
};

const borderConfig = {
  header: {
    color: "border",
    side: "both",
    size: "small"
  },
  body: {
    color: "border",
    side: "both",
    size: "small"
  }
}; 

class DisputesPage extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      loading: false,
      disputes: [],
    };
  }
 
  componentDidMount() {
    this.setState({ loading: true });
 
    this.props.firebase.db.ref('connect_cancels').on('value', async snapshot => {
      const disputes = Object.values(snapshot.val() || {});
      var results = [];
      for (var i = 0; i < disputes.length; i++) {
        const dispute = disputes[i];
        const connect = (await this.props.firebase.db.ref(`connects/${dispute.connect_id}`).once("value")).val();
        const targetAlias = (await this.props.firebase.db.ref(`profiles/${connect.target_user_id}/alias`).once("value")).val();
        const sourceAlias = (await this.props.firebase.db.ref(`profiles/${connect.source_user_id}/alias`).once("value")).val();

        results.push({
          dispute,
          connect,
          targetAlias,
          sourceAlias,
        });
      }

      this.setState({
        disputes: results,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.db.ref('connect_cancels').off();
  }
 
  render() {
    const { disputes, loading } = this.state;

    return (
      <div>
        <h1>Disputes ({disputes.length})</h1>
 
        {loading && <div>Loading ...</div>}

        <DisputeList disputes={disputes} onClickUid={(uid) => this.props.history.push('/connects/' + uid)} />
      </div>
    );
  }
}

const DisputeList = ({ disputes, onClickUid }) => (
  <DataTable
    sortable={true}
    step={30}
    primaryKey="connect.id"
    border={borderConfig}
    onClickRow={({ datum }) => {
      onClickUid(datum.connect.id);
    }}
    onSort={(obj) => tableSort({ disputes, ...obj }) }
    sort={
      {
        direction: 'desc',
        property: 'dispute.filed_at'
      }
    }
    columns={[
      {
        property: 'connect.id',
        header: <Text>ID</Text>,
        search: true,
        sortable: false,
      },
      {
        property: 'targetAlias',
        header: <Text>Seller</Text>,
        search: true,
        sortable: true,
      },
      {
        property: 'sourceAlias',
        header: <Text>Buyer</Text>,
        search: true,
        sortable: true,
      },
      {
        property: 'dispute.reason',
        header: <Text>Cancel Reason</Text>,
        search: true,
        sortable: true,
      },
      {
        header: <Text>Filed At</Text>,
        property: 'created_at',
        render: datum => {
          const timestamp = datum.dispute.filed_at;
          return (
            <Text>{timestamp ? moment(timestamp).fromNow() : 'never'}</Text>
          )
        },
        sortable: true,
      },
    ]}
    data={disputes}
  />
);
 
export default compose(
  withRouter,
  withFirebase,
)(DisputesPage);